import React, { useContext, useRef, useEffect, useState } from "react";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [view, setView] = useState("about");
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksToggleRef = useRef(null);

  useEffect(() => {
    if (showLinks) {
      linksContainerRef.current.style.height = `75px`;
      linksToggleRef.current.style.transform = `rotate(90deg)`;
    } else {
      linksContainerRef.current.style.height = 0;
      linksToggleRef.current.style.transform = `rotate(0deg)`;
    }
  }, [showLinks]);

  const clickLinks = (e) => {
    setView(e.target.id);
    setShowLinks(false);
  };

  const clickBars = () => {
    setShowLinks(!showLinks);
  };

  return (
    <AppContext.Provider
      value={{
        view,
        clickLinks,
        clickBars,
        linksContainerRef,
        linksToggleRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
