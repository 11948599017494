import React from "react";
import projects from "./projectList";
import Projectlang from "./Projectlang";

const Projects = () => {
  return (
    <section className="projects-container">
      {projects.map((project, index) => {
        const { img, title, link, language, desc } = project;
        return (
          <div key={index} className="single-project-container">
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img src={img} alt="" className="project-logo" />
              <h1 className="project-title">{title}</h1>
            </a>

            <p className="project-desc">{desc}</p>
            <Projectlang language={language} />
          </div>
        );
      })}
    </section>
  );
};

export default Projects;
