import React from "react";
import { useGlobalContext } from "./context";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
  const {
    clickLinks,
    clickBars,
    linksContainerRef,
    linksToggleRef,
  } = useGlobalContext();

  return (
    <nav className="navbar">
      <div>
        <button
          className="nav-toggle"
          ref={linksToggleRef}
          aria-label="navigation toggle"
        >
          <FaBars onClick={() => clickBars()} />
        </button>
      </div>
      <div>
        <div className="links-container" ref={linksContainerRef}>
          <div className="single-link-container">
            <button
              id="about"
              onClick={(e) => clickLinks(e)}
              className="nav-link"
            >
              About
            </button>
          </div>
          <div className="single-link-container">
            <button
              id="projects"
              onClick={(e) => clickLinks(e)}
              className="nav-link"
            >
              Projects
            </button>
          </div>
          <div className="single-link-container">
            <button
              id="contact"
              onClick={(e) => clickLinks(e)}
              className="nav-link"
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
