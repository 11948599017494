const projects = [
  {
    title: "Reinforced Concrete Wall and Beam Design Automation",
    language: ["structural", "VBA", "automation"],
    desc: "Optimize rebar design without the need of human interference which translate to freeing up Engineer’s time for a more cognitive task.",
    link: "https://drive.google.com/file/d/1Mc50qaDqppOj1BcD0uCYiy10gDSGpQiD/view?usp=sharing",
    img: "/img/webp/rebar_sizing.webp",
  },
  {
    title: "Diaphragm Wall Rebar Drawing Automation",
    language: ["structural", "phyton", "automation"],
    desc: "Streamline and accelerate diaphragm wall rebar drawing process by removing traditional and repetitive processes.",
    link: "https://drive.google.com/file/d/1kOEk75Wu8F3yu0QFh55uI3k6z9z8097g/view?usp=sharing",
    img: "/img/webp/dwall_draw.webp",
  },
  {
    title: "Truss Optimization",
    language: ["structural", "evolutionary algorithm", "MATLAB"],
    desc: "Truss optimization using evolutionary algorithm. FEA program used in this research is self-developed using MATLAB.",
    link: "https://drive.google.com/file/d/1gYiav_0zn5blgUrUoICyiClutya5KwhE/view?usp=sharing",
    img: "/img/webp/truss_optimization.webp",
  },
  {
    title: "Wheregotwater",
    language: ["css", "html", "leaflet", "beta"],
    desc: "Shows drinking water fountain around Singapore.",
    link: "https://wheregotwater.netlify.app/",
    img: "/img/webp/wheregotwater.webp",
  },
  {
    title: "Stripe Submenus",
    language: ["react"],
    desc: "Recreate stripe landing page.",
    link: "https://stefanokali-stripes.netlify.com",
    img: "/img/webp/stripe.webp",
  },
  {
    title: "Landing Page",
    language: ["css", "html"],
    desc: "Responsive product landing page.",
    link: "https://codepen.io/stefanokali/full/bGeQzPb",
    img: "/img/webp/landing_page.webp",
  },
  {
    title: "Cocktails",
    language: ["react", "router", "RESTAPI"],
    desc: "Fetch data from CocktailDB API with search functionality.",
    link: "https://stefanokali-cocktails.netlify.app",
    img: "/img/webp/cocktails.webp",
  },
  /*{
    title: "Color Generator",
    language: ["react"],
    desc: "Shades color generator.",
    link: "https://stefanokali-color-generator.netlify.app",
    img: "/img/webp/color_generator.webp",
  },
  {
    title: "Grocery Bud",
    language: ["react"],
    desc:
      "Simple grocery note app with edit, delete and local caching functionality.",
    link: "https://stefanokali-grocery-bud.netlify.app",
    img: "/img/webp/grocery_bud.webp",
  },*/
];

export default projects;
