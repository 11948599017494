import "./App.css";
import About from "./About";
import Navbar from "./Navbar";
import Projects from "./Projects";
import Contact from "./Contact";
import { useGlobalContext } from "./context";

function App() {
  const { view } = useGlobalContext();

  return (
    <div
      className="container"
      style={{
        backgroundImage: "url('/img/webp/background_building_white.webp')",
      }}
    >
      <Navbar />
      {view === "about" ? <About /> : null}
      {view === "projects" ? <Projects /> : null}
      {view === "contact" ? <Contact /> : null}
    </div>
  );
}

export default App;
