import React from "react";

const Contact = () => {
  return (
    <div className="contact-container">
      <div></div>
      <div className="contact-text">
        <h2>Contact me at:</h2>
        <p>Email: hello@stefanokali.com</p>
        <div className="contact-link-container">
          <a
            href="https://www.linkedin.com/in/stefanokusumaali/"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <img
              src="logo/webp/linkedin.webp"
              alt=""
              className="contact-logo"
            />
          </a>
          <a
            href="https://github.com/stefanokali"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <img src="logo/webp/github.webp" alt="" className="contact-logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
