import React from "react";

export const Home = () => {
  return (
    <section className="about-container">
      <div></div>
      <div className="about-text">
        <h2>Hi my name is</h2>
        <h2>Stefano Kusuma Ali</h2>
        <p>
          I’m a structural engineer passionate in developing digital solutions
          and process automation
        </p>
      </div>
    </section>
  );
};

export default Home;
