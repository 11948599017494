import React from "react";

const Projectlang = ({ language }) => {
  return (
    <div className="project-language-container">
      {language.map((lang, index) => {
        return (
          <div key={index} className="single-language-container">
            <p>{lang}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Projectlang;
